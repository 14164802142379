export default {
    "vehicles.get": (request, context) => {
        return request;
    },
    "segment.post": (request, context) => {
        return request;
    },
    "plan.post": (request, context) => {
        return request;
    },
    "plan-execute.post": (request, context) => {
        return request;
    },
    "isalive.get": (request, context) => {
        return request;
    },
    "pots.get": (request, context) => {
        return request;
    },
    "stations.get": (request, context) => {
        return request;
    },
    "geocoder.get": (request, context) => {
        return request;
    },
    "reversegeocoder.get": (request, context) => {
        return request;
    },
    "plans-download.post": (request, context) => {
        const generate_id = function() {
            return "xxxx-xxxx-xxxx-xxxx".replace(/x/g, () => {
                return Math.floor(Math.random() * 16).toString(16);
            });
        };

        const payload = request.payload;

        payload.id = generate_id();

        // Map origin and destination to geoCoordinates
        if (payload.origin.geoLocation) {
            payload.origin.geoCoordinate = payload.origin.geoLocation;
            delete payload.origin.geoLocation;
        } // end if
        if (payload.destination.geoLocation) {
            payload.destination.geoCoordinate = payload.destination.geoLocation;
            delete payload.destination.geoLocation;
        } // end if

        // Remove unnecessary fields from the payload
        delete payload.searchTimeDirection, payload.timeWindowHours;

        // Replace departure with earliestDeparture
        payload.earliestDeparture = payload.departure;
        delete payload.departure;

        // Move maxResults to planningSettings
        payload.planningSettings = {
            maxResults: payload.searchSettings.maxResults,
        };
        delete payload.searchSettings;

        if (payload.modesSettings.ptSettings) {
            payload.modesSettings.ptSettings.maxTransfers = payload.modesSettings.ptSettings.maxPtInterchanges;
            payload.modesSettings.ptSettings.transferSpeedLevel = payload.modesSettings.ptSettings.transferSpeed;

        } // end if

        if (payload.modesSettings.walkSettings) {
            payload.modesSettings.walkSettings.walkSpeedLevel = payload.modesSettings.walkSettings.walkSpeed;
        } // end if

        delete payload.timeWindowHours, payload.modesSettings.ptSettings.userFareInfo;
        
        return request;
    },
    "plan-update.post": (request, context) => {
        return request;
    },
};
